export const msalConfig = {
    auth: {
      clientId: `${process.env.REACT_APP_CLIENTID}`,
      authority: "https://login.microsoftonline.com/common", 

      redirectUri: `${process.env.REACT_APP_REDIRECTURI}`,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  export const rtapiLoginRequest = {
    scopes: [`${process.env.REACT_APP_SCOPE}`]
   };
 
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

  export const rtapiConfig = {
    rtapiEndpoint: `${process.env.REACT_APP_RTAPIENDPOINT}`,
};

  