import { Link, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react"
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { getRTApi, getRTApiTxt, getRTFile } from "../rtapi";
import { rtapiLoginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";
import { useFetchSystemList } from "../hooks/SystemHooks"
import { InteractionStatus, EventType } from "@azure/msal-browser";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { OverviewSystem, System } from "../types/system";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { propTypes } from "react-bootstrap/esm/Image";
import Button from "react-bootstrap/Button";

const HomePageHeader = () => {

  return (
    <header className="header">
      <h4>System Overview</h4>
    </header>
  );
};

export const SystemOverview = () => {
  const { tenantid, viewid, systemid } = useParams();
  console.log('SystemOverview begin - systemid:' + systemid);

  // if (systemid == undefined || systemid == '00000000-0000-0000-0000-000000000000')
  //   console.log('system is guid empty');


  // const [searchParams, setSearchParams] = useSearchParams();
  // console.log('SystemOverview begin - reload: ' + searchParams.get("reload"));
  // if (searchParams.get("reload") == null) {
  //   console.log('SystemOverview begin - reload UNDEFINED');
  // }

  // console.log('SystemOverview begin - viewid:' + viewid);
  // console.log('SystemOverview begin - systemid:' + systemid);

  // if (!id) throw Error("Missing params");


  var calcFromDate = new Date();
  //var calcFromDate = new Date(2023, 1, 1);  // DEBUG ONLY

  calcFromDate.setDate(calcFromDate.getDate() - 30);
  console.log('calcFromDate: ' + calcFromDate);


  const nav = useNavigate();

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();


  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<any[]>([]);
  const [viewData, setViewData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(calcFromDate);
  const [endDate, setEndDate] = useState(new Date());
  const [detailDate, setDetailDate] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  console.log('startDate: ' + startDate);

  const routeChange = (tenantid: any, viewid: any, systemid: any, date: any) => {
    let path = `/systemdetail/${tenantid}/${viewid}/${systemid}/${date}`;

    console.log(path);

    //viewid, systemid, date

    nav(path, { replace: true });
  }

  const handleSystemChange = (e: any) => {

    // console.log(e.target.value);
    // debugger;

    e.preventDefault();

    nav(`/systemoverview/${tenantid}/${viewid}/${e.target.value}`);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
    var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + e.target.value + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;
      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const handleFromDateChange = (e: any) => {

    //debugger;

    var date = new Date(e);
    setStartDate(date);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }
    // console.log('endDate: ' + endDate);

    var dateTxt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
    //    const url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt;

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;
    else
      url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + dateTxt + "&todate=" + endDateTxt;

    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const handleToDateChange = (e: any) => {

    //debugger;

    var date = new Date(e);
    setEndDate(date);

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
    var endDateTxt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    //    const url2 = "v2/portal/overview?systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;

    let url2 = "";
    if (systemid === undefined)
      url2 = "v2/portal/overview?systemid=&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt + "&tenantid=" + tenantid;
    else
      url2 = "v2/portal/overview?systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt + "&tenantid=" + tenantid;


    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTApi(url2, response.accessToken).then((data2) => {
        setLoading(false);
        setApiData(data2);
      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  const handleExportSystem = (e: any) => {

    //debugger;


    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
    var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
    const url2 = "v2/portal/overview/export?tenantid=" + tenantid + "&systemid=" + systemid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;

    instance.acquireTokenSilent(tokenRequest).then((response) => {

      getRTFile(url2, response.accessToken, systemid + '.xlsx').then((data2) => {

      })
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }


  const sayHello = (name: string) => {
    setDetailDate(name);
    alert(`Hello, ${name}!`);
  };

  const styleObject = {
    "font-size": "11px",
  }

  const handleClick = (e: any) => {

    //debugger;

    e.preventDefault();

    let path = `/systemdetail/${viewid}/${systemid}/${detailDate}`;

    console.log(path);

    nav(path, { replace: true });


    console.log('The link was clicked.');
  }


  useEffect(() => {

    const callbackId = instance.addEventCallback((message: { eventType: EventType; payload: any; }) => {
      // This will be run every time an event is emitted after registering this callback
      // console.log('xy4711: ' + message.eventType);
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        window.location.reload();
        // Do something with the result
      }
    });

    // console.log('SYSTEMOverview a, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

    if (!loading && (inProgress === InteractionStatus.None || inProgress === InteractionStatus.Startup) && accounts.length > 0) {

      // console.log('SYSTEMOverview b, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

      if (apiData && apiData.length > 0) {

        // console.log('SYSTEMOverview c, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress + ' accounts.length: ' + accounts.length + ' isAuthenticated: ' + isAuthenticated);

        // Skip data refresh if already set - adjust logic for your specific use case
        // console.log('skipping here');
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: rtapiLoginRequest.scopes
      }

      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {

        // console.log('SYSTEMOverview d, loading: ' + loading + ' apiData.length: ' + apiData.length + ' viewid: ' + viewid + ' inProgress: ' + inProgress);

        const url = "v2/portal/viewsystems?tenantid=" + tenantid + "&viewid=" + viewid;
        getRTApi(url, response.accessToken).then((data) => {

          console.log('should we load view data here???');
          if (systemid === undefined) {
            console.log('should we load view data here  AAA');
            setViewData(data);
          }
          else {
            console.log('should we load view data here  BBB');
            setViewData(data);
          }


        }).then(() => {

          var fromDateTxt = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
          var endDateTxt = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();

          let url2 = "";
          if (systemid === undefined)
            url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;
          else
            url2 = "v2/portal/overview?tenantid=" + tenantid + "&systemid=" + systemid + "&viewid=" + viewid + "&fromdate=" + fromDateTxt + "&todate=" + endDateTxt;

          console.log('URL2' + url2);

          getRTApi(url2, response.accessToken).then((data2) => {
            // console.log('data2 B length'  + data2.length);
            setApiData(data2);
            setLoading(false);
          })
        }
        );
      }).catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, []);

  //inProgress, accounts, instance, loading, apiData, viewData, startDate, endDate, detailDate



  return (

    <div>
      <div className="row mb-2">
        <h5 className="themeFontColor">
          System overview
        </h5>
      </div>

      <div className="row mb-2">
        <div id="block_container">
          <div id="bloc1">From:</div>
          <div id="bloc2">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              wrapperClassName="datePicker"
              selected={startDate}
              onChange={handleFromDateChange}
              popperPlacement="top-end"
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [5, 10],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
            />
          </div>

          <div id="bloc3">To:</div>
          <div id="bloc4">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              wrapperClassName="datePicker"
              selected={endDate}
              onChange={handleToDateChange}
              popperPlacement="top-end"
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [5, 10],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
            />
          </div>

          {
            viewData && viewData.length >= 1 ?
              (
                <div id="bloc3">System:</div>
              ) : null
          }

          <div>
            {
              viewData && viewData.length >= 1 ?
                (
                  <div id="bloc4">
                    <div>
                      <br></br>
                      <select onChange={handleSystemChange} value={systemid}>
                        <option value=""></option>
                        {viewData.map((h: OverviewSystem) => <option key={h.systemId} value={h.systemId}>{h.title}</option>)}
                      </select>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                ) : null
            }
          </div>

          <div id="blocspace"></div>
          <div id="blocexport"><Button hidden={systemid == undefined} onClick={handleExportSystem}>Export</Button></div>
        </div>
      </div>




      <table className="check" id="tableOverView">
        <tbody>
          {apiData &&
            apiData.map((h: System) => (
              <tr key={h.id}>
                <td className="DateClass">{h.title}
                </td>
                {h.overviewSystems.map((hh: OverviewSystem) => (
                  <td key="`${h.id}-${hh.systemId}`" className={`${hh.className} overflow`} onClick={() => nav(`/systemdetail/${tenantid}/${viewid}/${hh.systemId}/${h.id}?root=${systemid == undefined}`)} >
                    {hh.title}</td>
                )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};



