import './App.css';
import React, { useEffect, StrictMode } from "react";
import { PageLayout } from "./components/PageLayout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SystemOverview } from "./components/SystemOverview";
import { ViewOverview } from "./components/ViewOverview";
import SystemDetails from "./components/SystemDetails";
import TaskDetails from "./components/TaskDetails";
import './fonts/BerkeleyMono/BerkeleyMono-Regular.woff'
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal, useAccount } from "@azure/msal-react";

function App() {
    const isAuthenticated = useIsAuthenticated();
    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const tenantid = account?.tenantId.toString() ?? '';

    useEffect(() => {
        document.title = "RestoreTest";
    }, []);

    return (
        <div>
            <PageLayout></PageLayout>
            <div className="container">
                <BrowserRouter>
                    <Routes>
                        <Route path="/viewoverview/:tenantid" element={<ViewOverview />} ></Route>
                        <Route path="/systemoverview/:tenantid/:viewid" element={<SystemOverview />} ></Route>
                        <Route path="/systemoverview/:tenantid/:viewid/:systemid" element={<SystemOverview />} ></Route>
                        <Route path="/systemdetail/:tenantid/:viewid/:systemid/:pickdate" element={<SystemDetails />} ></Route>
                        <Route path="/taskdetails/:tenantid/:id" element={<TaskDetails />} ></Route>
                        <Route path="/:tenantId" element={<ViewOverview />}></Route>

                        <Route path="/" element={isAuthenticated ? <ViewOverview /> : <ViewOverview /> }/>                        

                    </Routes>
                </BrowserRouter >
            </div>
        </div>
    );
}

export default App;