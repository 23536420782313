import { Navigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react"
import { useMsal, useAccount } from "@azure/msal-react";
import { getRTApi, getRTApiAnon } from "../rtapi";
import { rtapiLoginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";
import { InteractionStatus, EventType } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { ViewSystem } from "../types/system";
import { isGuid } from 'check-guid'

export const ViewOverview = () => {
  let { tenantid, viewid } = useParams();
  const nav = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [redirctTo, setRedirctTo] = useState(false); // your state value to manipulate

  const [jumpUrl, setJumpUrl] = useState(''); // your state value to manipulate

  const [noData, setNoData] = useState(false); // your state value to manipulate


  useEffect(() => {

    // console.log('VIEWOverview - a, loading: ' + loading + ' apiData.length: ' + apiData.length + ', tenantId: ' + tenantid);
    // console.log('props.tenantId ' + tenantid);
    // console.log('props.viewId ' + viewid);

    const callbackId = instance.addEventCallback((message: { eventType: EventType; payload: any; }) => {
      // This will be run every time an event is emitted after registering this callback
      // console.log('xy4711: ' + message.eventType);
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;
        window.location.reload();
        // Do something with the result
      }
    });


    if (tenantid != undefined) {
      console.log('resolvetenant BEFORE: ' + tenantid);

      var isTenantGuid = isGuid(tenantid);
      if (!isTenantGuid) {
        // Lookup tenant as string and get tenant as guid back.
        let url = "v2/resolvetenant?tenant=" + tenantid;
        getRTApiAnon(url).then((data) => {
          //tenantid = data;
          console.log('resolvetenant BEFORE: ' + tenantid);

          let url = window.location.toString();
          url = url.replace('/' + tenantid,'/' + data);

          console.log('resolvetenant url: ' + url);
        
          window.location.replace(url );
        });
      }
    }



    if (tenantid == undefined && account != null) {
      tenantid = account.tenantId;
      //console.log('VIEWOverview - aaaaaaaaaaaaaaaaa, loading: ' + loading + ' apiData.length: ' + apiData.length + ', tenantid: ' + tenantid);
    }

    if (!loading && inProgress === InteractionStatus.None && accounts.length > 0) {
      if (apiData && apiData.length > 0) {
        // Skip data refresh if already set - adjust logic for your specific use case
        console.log('Skip data refresh if already set - adjust logic for your specific use case');
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: rtapiLoginRequest.scopes
      }

      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        let url = "v2/portal/views";

        if (tenantid != undefined)
          url += "?tenantid=" + tenantid;

        if (tenantid != undefined && viewid != undefined)
          url = "v2/portal/viewsystems?tenantId=" + tenantid + "&viewId=" + viewid;

        console.log(url);

        getRTApi(url, response.accessToken).then((data) => {
          setApiData(data);

          //console.log('AAAA ' + data.length);
          //debugger;

          if (data.length == 0) {
            setNoData(true);
          }


          // if (data.length == 1) {
          //   let h = data[0];

          //   let hh = `${h.tenantId}/${h.viewId}/${h.firstSystemId}`;
          //   setJumpUrl(hh);
          //   setRedirctTo(true);
          // }

          //console.log('setLoading(true)');

          setLoading(true);
        });
      }).catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, [inProgress, accounts, instance, loading, apiData]);

  if (noData) {
    return (
      <div>
        <div className="row mb-2">
          <h5 className="themeFontColor text-center">
            No views found
          </h5>
        </div>
      </div>
    );
  }
  else if (redirctTo) {
    return <Navigate to={`/systemoverview/${jumpUrl}`} />
  } else {

    return (
      <div>
        <div className="row mb-2">
          <h5 className="themeFontColor text-center">
            View overview
          </h5>
        </div>
        <table className="check" id="tableOverView">
          <tbody>
            {apiData &&
              apiData.map((h: ViewSystem) => (
                <tr key={h.viewId}>
                  {/* <td>{new Intl.DateTimeFormat('en-US').format(h.date)}</td> */}
                  <td key="`${h.viewId}`" onClick={() => nav(`/systemoverview/${h.tenantId}/${h.viewId}`)} className={`${h.className} overflow`}>{h.title}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );

  }


};



