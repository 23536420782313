import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import React, { useState, useEffect } from "react"
import { InteractionStatus } from "@azure/msal-browser";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { SystemDetail, CheckDetail, TaskDetail } from "../types/system";
import { rtapiLoginRequest } from "../authConfig";
import { getRTApi, getRTApiTxt, postRTApi } from "../rtapi";
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';

import { Grid, Dialog, DialogContent, DialogActions, Button } from '@mui/material'

import ConfirmDialog from "./useConfirm"
import { Guid } from "guid-typescript";



function convertUTCDateToLocalDate(date: Date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}


const SystemDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isRoot = (searchParams.get("root") == "true" ? true : false);

  const { tenantid, viewid, systemid, pickdate } = useParams();
  if (!tenantid && !systemid && !pickdate) throw Error("Missing params");

  const nav = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [manuallyApproveConfirmOpen, setManuallyApproveConfirmOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleCheckboxChange = (event: any) => {
    const checkedId = event.target.value;
    const { value, checked } = event.target;

    //console.log('BEFORE - LENGTH OF ARRAY: ' + selectedIds.length + ' value: ' + value + ' checked: ' + checked);
    //selectedIds.forEach(d => console.log(d));

    if (event.target.checked) {
      console.log('checked');
      setSelectedIds([...selectedIds, checkedId])
    } else {
      console.log('un-checked');
      setSelectedIds(selectedIds.filter(id => id !== checkedId))
    }

    //console.log('AFTER - LENGTH OF ARRAY: ' + selectedIds.length);
    //selectedIds.forEach(d => console.log(d));
  }


  const deleteChecks = (comment: string) => {

    if (comment == '') return;

    selectedIds.forEach(d => console.log(d));

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    let bodyTxt = JSON.stringify({ checks: selectedIds, reason: comment });

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = "v2/portal/DeleteChecks?tenantid=" + tenantid;

      postRTApi(url, response.accessToken, bodyTxt).then((data) => {
        window.location.reload();
      });
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }

  function ConditionalRenderDeleteButton(props: any) {

    let allowDelete: boolean = allowDeleteCondition();

    if (allowDelete === true)
      console.log('ConditionalRenderDeleteButton result, allowDeleteCondition: TRUE    ****');
    else
      console.log('ConditionalRenderDeleteButton result, allowDeleteCondition: FALSE');

    return (
      <div>
        <IconButton aria-label="delete" hidden={!allowDelete || selectedIds.length == 0} onClick={() => setConfirmOpen(true)}>
          <DeleteIcon />
        </IconButton>
        <ConfirmDialog
          title="Delete check?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteChecks}
        >
          You must write a comment why you want to delete these checks?
        </ConfirmDialog>
      </div>
    );
  }


  function ConditionalRenderManualApproveButton(props: any) {

    let allowManualApprove: boolean = allowManualApproveCondition();

    if (allowManualApprove === true)
      console.log('ConditionalRenderManualApproveButton result, allowManualApprove: TRUE    ****');
    else
      console.log('ConditionalRenderManualApproveButton result, allowManualApprove: FALSE');

    return (
      <div>
        <IconButton aria-label="delete" hidden={!allowManualApprove || selectedIds.length == 0} onClick={() => setManuallyApproveConfirmOpen(true)}>
          <ThumbUpIcon />
        </IconButton>
        <ConfirmDialog
          title="Manually approve?"
          open={manuallyApproveConfirmOpen}
          setOpen={setManuallyApproveConfirmOpen}
          onConfirm={manuallyApproveChecks}
        >
          You must write a comment why you want to manually approve these checks?
        </ConfirmDialog>
      </div>
    );
  }


  function allowDeleteCondition(): boolean {
    let output: boolean = false;

    for (const [key, value] of Object.entries(apiData)) {
      const u = value as CheckDetail;
      if (u.allowDelete) {
        output = true;
        break;
      }
    }
    
    return output;
  }

  function allowManualApproveCondition(): boolean {
    let output: boolean = false;

    for (const [key, value] of Object.entries(apiData)) {
      const u = value as CheckDetail;
      if (u.allowManualApprove) {
        output = true;
        break;
      }
    }
    
    return output;
  }


  const manuallyApproveChecks = (comment: string) => {

    if (comment == '') return;

    selectedIds.forEach(d => console.log(d));

    const tokenRequest = {
      account: accounts[0], // This is an example - Select account based on your app's requirements
      scopes: rtapiLoginRequest.scopes
    }

    let bodyTxt = JSON.stringify({ checks: selectedIds, reason: comment });

    // Acquire an access token
    instance.acquireTokenSilent(tokenRequest).then((response) => {
      const url = "v2/portal/manuallyApproveChecks?tenantid=" + tenantid;

      postRTApi(url, response.accessToken, bodyTxt).then((data) => {
        window.location.reload();
      });
    }).catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      throw e;
    });
  }


  useEffect(() => {

    setConfirmOpen(false);
    setManuallyApproveConfirmOpen(false);



    //console.log('a, loading: ' + loading + ' apiData.length: ' + apiData.length);

    if (!loading && inProgress === InteractionStatus.None && accounts.length > 0) {

      //console.log('b, loading: ' + loading + ' apiData.length: ' + apiData.length);

      if (apiData && apiData.length > 0) {
        //console.log('c, loading: ' + loading + ' apiData.length: ' + apiData.length);

        //console.log(apiData);
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: rtapiLoginRequest.scopes
      }

      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {

        //console.log('d, loading: ' + loading + ' apiData.length: ' + apiData.length);

        const url = "v2/portal/systemdetails/" + systemid + "/" + pickdate + "/?tenantid=" + tenantid;
        getRTApi(url, response.accessToken).then((data) => {
          setApiData(data);
          setLoading(false);
        });
      }).catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, [inProgress, accounts, instance, loading, apiData, selectedIds]);


  return (
    <div>
      <Link to={`/systemoverview/${tenantid}/${viewid}/${!isRoot ? systemid : ""}`} className="btn btn-secondary btn-space">
        Back to system overview
      </Link>
      <div className="row mb-2">
        <h5 className="themeFontColor text-center">
          System details
        </h5>
      </div>
      <table className="check" id="tableOverView">
        <tbody>
          {apiData.map((check: CheckDetail) => (
            <tr key={`${check.dateTicks}`}>
              <td><input type="checkbox" id="deleteCheck" name="deleteCheck" hidden={!check.allowDelete && !check.allowManualApprove} value={check.checkId} onChange={(event) => { handleCheckboxChange(event); }} /></td>
              <td key={`${check.dateTicks}`} className="DateClass">{convertUTCDateToLocalDate(new Date(check.startDate)).toLocaleDateString('en-GB')}&nbsp;{convertUTCDateToLocalDate(new Date(check.startDate)).toLocaleTimeString('da-DK')}</td>
              <td key={`${check.checkId}`} className={`${check.checkClass} overflow`}>{check.title}</td>

              {check.tasks.map((task: TaskDetail) => (
                <td key={`${task.taskId}`} onClick={() => nav(`/taskdetails/${tenantid}/${task.taskId}`)} className={`${task.taskClass} overflow`}>{task.title}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <ConditionalRenderDeleteButton />
        <ConditionalRenderManualApproveButton />
      </div>

      {/* <div>
        <IconButton aria-label="delete" hidden={!allowDeleteCondition || selectedIds.length == 0} onClick={() => setConfirmOpen(true)}>
          <DeleteIcon />
        </IconButton>
        <ConfirmDialog
          title="Delete check?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteChecks}
        >
          You must write a comment why you want to delete these checks?
        </ConfirmDialog>
      </div> */}
      {/* <div>
        <IconButton aria-label="delete" hidden={selectedIds.length == 0} onClick={() => setManuallyApproveConfirmOpen(true)}>
          <ThumbUpIcon />
        </IconButton>
        <ConfirmDialog
          title="Manually approve?"
          open={manuallyApproveConfirmOpen}
          setOpen={setManuallyApproveConfirmOpen}
          onConfirm={manuallyApproveChecks}
        >
          You must write a comment why you want to manually approve these checks?
        </ConfirmDialog>
      </div> */}
    </div>
  );
};

export default SystemDetails;
