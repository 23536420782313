import React, { useState, useEffect } from "react"
import { useMsal, useAccount } from "@azure/msal-react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { GraphObject } from "../types/system";


export const PageLayout = () => {
    const isAuthenticated = useIsAuthenticated();
    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [graphData, setGraphData] = useState(null);

    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    useEffect(() => {
        if (isAuthenticated) {
            instance.acquireTokenSilent(request).then((response) => {
                callMsGraph(response.accessToken).then(response => setGraphData(response));
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    callMsGraph(response.accessToken).then(response => setGraphData(response));
                });
            });

        }
    }, [account, instance]);

    if (accounts.length > 0) {
        //console.log('a');
        //console.log(JSON.stringify(graphData));

        const loggedonUserEmail = graphData != null ? (graphData as GraphObject).mail : '';
        
        // TODO const currentMail = graphData ? graphData.mail : "";
        // {JSON.stringify(graphData)}

        return (
            <>
                <Navbar bg="primary" variant="dark">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">RestoreTest</a>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            </ul>
                            <span className="navbar-text">
                                {
                                graphData ?
                                    // <span id="test" className="welcomeName">Welcome {graphData.userPrincipalName}</span>
                                    <span id="test" className="welcomeName">{loggedonUserEmail}</span>
                                    :
                                    <span />
                                }
                                <SignOutButton />
                            </span>
                        </div>
                    </div>
                </Navbar>
                <br></br>
                
            </>
        );
    }
    else {
        return (
            <>
                <Navbar bg="primary" variant="dark">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">RestoreTest</a>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <span className="navbar-text">
                                <SignInButton />
                            </span>
                        </div>
                    </div>
                </Navbar>
            </>
        );
    }

};
