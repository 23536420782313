import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import React, { useState, useEffect } from "react"
import { InteractionStatus } from "@azure/msal-browser";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { SystemDetail, CheckDetail, TaskDetail, CommandDetail } from "../types/system";
import { rtapiLoginRequest } from "../authConfig";
import { getRTApi, getRTApiTxt } from "../rtapi";

interface LogProps {
  txt: string;
}

function ShowLog(props: LogProps) {

  const [show, setShow] = useState(false);

  return (
    <div>
        <a href="#" onClick={() => setShow(!show)}>
          { show ? 'Close' : 'Read more...' }
          </a>
      { show && (
          <div dangerouslySetInnerHTML={{ __html: props.txt }}></div>
      )}
      </div>
  );
}

const TaskDetails = () => {
  const { tenantid, id } = useParams();
  if (!id) throw Error("Missing params");
  if (!tenantid) throw Error("Missing params");

  const nav = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    //console.log('a, loading: ' + loading + ' apiData.length: ' + apiData.length);

    if (!loading && inProgress === InteractionStatus.None && accounts.length > 0) {
      if (apiData && apiData.length > 0) {
        console.log(apiData);
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: rtapiLoginRequest.scopes
      }

      // Acquire an access token
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        const url = "v2/portal/taskdetails/" + id + "/?tenantid=" + tenantid;

        getRTApi(url, response.accessToken).then((data) => {
          setApiData(data);
          setLoading(false);
        });
      }).catch(async (e) => {
        // Catch interaction_required errors and call interactive method to resolve
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(tokenRequest);
        }

        throw e;
      });
    }
  }, [inProgress, accounts, instance, loading, apiData]);




  return (

    <div>
      <Link onClick={() => nav(-1)} to=''
        className="btn btn-secondary btn-space me-1">
        Back to system details
      </Link>
      <div className="row mb-2">
        <h5 className="themeFontColor text-center">
          Task details
        </h5>
      </div>
      <table className="DialogTable" id="tableOverView">
        <tbody>
          <tr className="GridViewHeaderStyle"><th>Title</th><th>Type</th><th>Status</th><th>Duration</th><th>Logs</th></tr>
          {apiData.map((command: CommandDetail) => (
            <tr key={`${command.htmlKey}`} className={`${command.commandClass}`}>

              <td>{command.title}</td>
              <td>{command.commandType}</td>
              <td>{command.commandStatus}</td>
              <td>{command.commandDuration}</td>
              {command.logs.startsWith('<table><tr><td><b>Output</b>')
                ? <td><ShowLog txt={command.logs}></ShowLog></td>
                : <td dangerouslySetInnerHTML={{ __html: command.logs }}></td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  );
};

export default TaskDetails;
