import { rtapiConfig } from "./authConfig";

export async function getRTApiAnon(endpoint) {
    const headers = new Headers();

    const options = {
        method: "GET",
        headers: headers
    };

    const url = rtapiConfig.rtapiEndpoint + endpoint;
    console.log(url);

    return fetch(url, options)

        .then(response => response.json())
        // .then(function (response) {
        //     var data = response.json()
        //     data.then(function (output) {
        //         console.log("output:", output)
        //     })
        // })
        .catch(error => console.log(error));
}

export async function getRTApi(endpoint, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    // headers.append("Cache-Control", 'no-cache');
    // headers.append("Pragma", 'no-cache');

    const options = {
        method: "GET",
        headers: headers
    };

    const url = rtapiConfig.rtapiEndpoint + endpoint;
    console.log(url);

    return fetch(url, options)

        .then(response => response.json())
        // .then(function (response) {
        //     var data = response.json()
        //     data.then(function (output) {
        //         console.log("output:", output)
        //     })
        // })
        .catch(error => console.log(error));
}

export async function postRTApi(endpoint, accessToken, bodytxt) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    
    const options = {
        method: "POST",
        headers: headers,
        body: bodytxt
    };

    const url = rtapiConfig.rtapiEndpoint + endpoint;
    console.log(url);

    return fetch(url, options)

        .then(response => response.json())
        // .then(function (response) {
        //     var data = response.json()
        //     data.then(function (output) {
        //         console.log("output:", output)
        //     })
        // })
        .catch(error => console.log(error));
}

function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export async function getRTFile(endpoint, accessToken, fileName) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers
    };

    const url = rtapiConfig.rtapiEndpoint + endpoint;

    fetch(url, options)
        .then(res => res.blob())
        .then(blob => {
            download(blob, fileName);
        })
        .catch(error => console.log(error));
}

export async function getRTApiTxt(endpoint, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const url = rtapiConfig.rtapiEndpoint + endpoint;
    console.log(url);

    return fetch(url, options)
        .then(response => response.text())
        .catch(error => console.log(error));
}